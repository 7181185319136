import React, {useState, useEffect, useRef} from 'react'
import styled from "styled-components";

import "./CashFlow style/CashFlow.css";

//component
import CashFlowPopup from './CashFlowPopup.js';

//icon
import { LuFileSpreadsheet } from "react-icons/lu";
import { FaEye } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import { FaCompressAlt } from "react-icons/fa";
import { FaExpandAlt } from "react-icons/fa";
import { PiNumberOneBold } from "react-icons/pi";
import { PiNumberThreeBold } from "react-icons/pi";

//axios
import axios from "axios";
import ImportiPopup from './ImportiPopup.js';
import moment from 'moment';

function CashFlowTable({mesiPass, mesiNuoviPass, dati, importi, fatture, fattureAcquisto, fattureAcquistoRiba, ricarica, aggiornamento, tipoRicarica, setTipoRicarica, fattureRiba, nascosti, ricaricaLeggera, visualizzazioneTipo}) {
    
    const idEntrate = [0, 1000, 2000, 3000, 4000];
    const idUscite = [0, 1000, 2000, 3000, 4000, 5000]; 

    const [mesi, setMesi] = useState(null);
    const [mesiNuovi, setMesiNuovi] = useState([
        {mese: 'Mese non valido', anno: 2024},
        { mese: 'Gennaio', anno: 2024 },
        { mese: 'Febbraio', anno: 2024 },
        { mese: 'Marzo', anno: 2024 },
        { mese: 'Aprile', anno: 2024 },
        { mese: 'Maggio', anno: 2024 },
        { mese: 'Giugno', anno: 2024 },
        { mese: 'Luglio', anno: 2024 },
        { mese: 'Agosto', anno: 2024 },
        { mese: 'Settembre', anno: 2024 },
        { mese: 'Ottobre', anno: 2024 },
        { mese: 'Novembre', anno: 2024 },
        { mese: 'Dicembre', anno: 2024 },
        { mese: 'Gennaio', anno: 2025 },
        { mese: 'Febbraio', anno: 2025 },
        { mese: 'Marzo', anno: 2025 },
        { mese: 'Aprile', anno: 2025 },
        { mese: 'Maggio', anno: 2025 },
        { mese: 'Giugno', anno: 2025 },
        { mese: 'Luglio', anno: 2025 },
        { mese: 'Agosto', anno: 2025 },
        { mese: 'Settembre', anno: 2025 },
        { mese: 'Ottobre', anno: 2025 },
        { mese: 'Novembre', anno: 2025 },
        { mese: 'Dicembre', anno: 2025 }
        
    ]);
    
    const [previsione, setPrevisione] = useState(12);
    const [indiceCorrente, setIndiceCorrente] = useState(0);

    moment.locale('it');
    const meseCorrenteTemp = moment().format('MMMM')
    const meseCorrente = meseCorrenteTemp.charAt(0).toUpperCase() + meseCorrenteTemp.slice(1);
    const indiceMese = moment().month();
    const annoSuccessivo = parseInt(moment().format('YYYY'))+1;
    const [effettoEseguito, setEffettoEseguito] = useState(false);

    useEffect(() => {
        if (!effettoEseguito) {
            const meseDaCercare = mesiPass[12 - ((12 - indiceMese) + 1)];
            const indice = mesiNuovi.findIndex(item => item.mese === meseDaCercare && item.anno === annoSuccessivo);

            if (indice !== -1) { // Controlla se l'indice è valido
                const nuovoArray = mesiNuovi.slice(0, indice + 1);
                setMesiNuovi(nuovoArray);
            }

            setEffettoEseguito(true); // Segna che l'effetto è stato eseguito una volta
        }
    
    }, [mesiNuovi, indiceMese, annoSuccessivo]);

    useEffect(() => {
        console.log(mesiNuovi);
    }, [mesiNuovi])

    //visualizzazione iva
    const [visualizzazione, setVisualizzazione] = useState(null);

    useEffect(() => {
        setVisualizzazione(visualizzazioneTipo);
    }, [visualizzazioneTipo])  
   
    
    function handleRicarica(){
        console.log('chiamata ricarica!')
        ricarica();
    }   


    const [completo, setCompleto] = useState(1);

    function radioSelection(){
        if(completo === 1){
            setCompleto(2);
        }else{
            setCompleto(1);
        }
    }

    //mostra nascosti
    const [elementiNascosti, setElementiNascosti] = useState(false);

    function handleElementiNascosti(){
        setElementiNascosti(!elementiNascosti);
    }


    //importi popup
    const [importiMese, setImportiMese] = useState(null);
    const [importiCategoria, setImportiCategoria] = useState(null);
    const [displayImporti, setDisplayImporti] = useState(null);
    const [tipoImporti, setTipoImporti] = useState(null);

    function handleImportiPopup(mese, categoria, tipo){
        setImportiMese(mese);
        setImportiCategoria(categoria);
        if(tipo === 'succ'){
            setTipoImporti(tipo);
        } else {
            setTipoImporti('attuale');
        }
        
        setDisplayImporti(!displayImporti)
    }

    function displayBanca(item, indice){
        if(completo === 1){
            return(
                <React.Fragment>
                    {item.id !== 2000 && (
                        <tr>
                            <td className={item.id === 1000 ? ('block first-column totali') : ('block first-column')}>{item.categoria}</td>
                            {showMore && (<td className={item.id === 1000 ? (' totali corto') : ('corto')}></td>)}
                            {showMore ? (
                                <td className={item.id === 1000 ? ('column totali') : ('column')}></td>
                            ) : (
                                <td className={item.id === 1000 ? ('corto totali') : ('corto')}></td>
                            )}

                            {mesi.map((mesi, index) => (
                                <>
                                    {showMore ? (
                                        <React.Fragment key={mesi}>
                                            

                                            {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                            <td className={item.id === 1000 ? 'column totali' : 'column'}>
                                                <div className='fatture-cash'>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}<EyeIcon />
                                                </div>
                                            </td>
                                            ) : (
                                            <td className={item.id === 1000 ? 'column totali' : 'column'}>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}
                                            </td>
                                            )}

                                            
                                        </React.Fragment>
                                    ) : (
                                        <>  
                                            
                                            {index === indiceMese && (
                                                <td className={item.id === 1000 ? 'column totali' : 'column'}></td>
                                            )}

                                            {index > indiceMese - 1 && (
                                                <React.Fragment key={mesi}>
                                                    {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                                    <td className={item.id === 1000 ? 'column totali' : 'column'}>
                                                        <div className='fatture-cash'>
                                                            {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                            style: 'currency',
                                                            currency: 'EUR'
                                                            })}<EyeIcon />
                                                        </div>
                                                    </td>
                                                    ) : (
                                                    <td className={item.id === 1000 ? 'column totali' : 'column'}>
                                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                        })}
                                                    </td>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}

                            {indiceCorrente >= 12 && (
                                <>
                                    {mesi.map((mese, indiceMese) => (
                                        <React.Fragment key={indiceMese}>
                                            {(indiceMese <= indiceCorrente-12) && (
                                                <>
                                                    {dati.successivi.banca.map((itemInner, index) => (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className={item.id === 1000 ? 'column totali' : 'column'}>
                                                                    {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR'
                                                                    })}
                                                                </td>
                                                            )}
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </React.Fragment>
                                        
                                    ))}
                                </>
                            )}
                            
                        </tr>
                    )}
                </React.Fragment>
            )
        } else {
            return(
                <React.Fragment>
                    {item.id === 1000 && (
                        <tr>
                            <td className='block first-column'>{item.categoria}</td>
                            {showMore && (<td className='corto'></td>)}
                            {showMore ? (
                                <td className='column'></td>
                            ) : (
                                <td className='corto'></td>
                            )}

                            {mesi.map((mesi, index) => (
                                <>
                                    {showMore ? (
                                        <React.Fragment key={mesi}>
                                            {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                            <td className='column'>
                                                <div className='fatture-cash'>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}<EyeIcon />
                                                </div>
                                            </td>
                                            ) : (
                                            <td className='column'>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}
                                            </td>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <>
                                            {index === indiceMese && (
                                                <td className='column'></td>
                                            )}
                                            {index > indiceMese-1 && (
                                                <React.Fragment key={mesi}>
                                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                                <td className='column'>
                                                    <div className='fatture-cash'>
                                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                        })}<EyeIcon />
                                                    </div>
                                                </td>
                                                ) : (
                                                <td className='column'>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}
                                                </td>
                                                )}
                                            </React.Fragment>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                            {indiceCorrente >= 12 && (
                                <>
                                    {mesi.map((mese, indiceMese) => (
                                        <React.Fragment key={indiceMese}>
                                            {(indiceMese <= indiceCorrente-12) && (
                                                <>
                                                    {dati.successivi.banca.map((itemInner, index) => (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className='column'>
                                                                    {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR'
                                                                    })}
                                                                </td>
                                                            )}
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </React.Fragment>
                                        
                                    ))}
                                </>
                            )}
                        </tr>
                    )}
                </React.Fragment>
            )
        }
    }


    async function handleNascondi(id, tipo, destinazione){
        const passData = {
            id: id,
            tipo: tipo, 
            dest: destinazione
        }

        try {
            const response = await axios.post("https://www.app.nauticsm.it/nascondi-cashflow", passData);
            ricaricaLeggera(); // Chiamata a handleRicarica
        } catch (error) {
            console.error("Errore nella richiesta:", error);
        }

    }


    function displayEntrate(item, indice){
        const idRidotti = [0,1000,2000,3000,4000];
        if(completo === 1){
            
            if(!nascosti.entrate.includes(item.id)){
                return(
                    <tr>
                        <td className={idEntrate.includes(item.id) ? item.id === 4000 ? ('block first-column totali') : ('block first-column last') : ('block first-column')}>
                        <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            <span>{item.categoria}</span>
                            {!idEntrate.includes(item.id) && (
                                <span className='eye-firstColumn' style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                                <EyeIcon onClick={() => handleNascondi(item.id, 'nascondi', 'entrate')} />
                                </span>
                            )}
                            </div>
                        </div>
                        </td>



                        {showMore && (<td className={idEntrate.includes(item.id) ? item.id === 4000 ? (' totali corto') : (' last corto') : (' corto') }></td>)}

                        {showMore ? (
                            <td className={idEntrate.includes(item.id) ? item.id === 4000 ? ('column totali') : ('column last') : ('column ') }></td>
                        ) : (
                            <td className={idEntrate.includes(item.id) ? item.id === 4000 ? ('corto totali') : ('corto last') : ('corto ') }></td>
                        )}
    
                        {mesi.map((mese, index) => (
                            <>
                                {showMore ? (
                                    <React.Fragment key={mese}>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mese) ? (
                                        <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali' : 'column last') : 'column'}>
                                            <div className='fatture-cash'>
                                                {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mese, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali' : 'column last') : 'column'}>
                                            {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <>
                                        {index === indiceMese && (
                                            <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali' : 'column last') : 'column'}></td>
                                        )}
                                        {index > indiceMese-1 && (
                                            <React.Fragment key={mese}>
                                            {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mese) ? (
                                            <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali' : 'column last') : 'column'}>
                                                <div className='fatture-cash'>
                                                    {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}<EyeIcon onClick={() => handleImportiPopup(mese, item.categoria)}/>
                                                </div>
                                            </td>
                                            ) : (
                                            <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali' : 'column last') : 'column'}>
                                                {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}
                                            </td>
                                            )}
                                        </React.Fragment>
                                        )}
                                        
                                    </>
                                )}
                            </>
                        ))}

                        {indiceCorrente >= 12 && (
                            <>
                                {mesi.map((mese, indiceMese) => (
                                    <React.Fragment key={indiceMese}>
                                        {(indiceMese <= indiceCorrente-12) && (
                                            <>
                                                {dati.successivi.entrate.map((itemInner, index) => (
                                                    <>  
                                                        {dati.successivi.macro.some(importo => importo.categoria === itemInner.categoria && importo.mese === mese) ? (
                                                            <>
                                                                {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                    <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali' : 'column last') : 'column'}>
                                                                        <div className='fatture-cash'>
                                                                            {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                            style: 'currency',
                                                                            currency: 'EUR'
                                                                            })}<EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')}/>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                    <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali' : 'column last') : 'column'}>
                                                                        {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                        style: 'currency',
                                                                        currency: 'EUR'
                                                                        })}
                                                                    </td>
                                                                )}
                                                            </>
                                                        )}
                                                        
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </React.Fragment>
                                    
                                ))}
                            </>
                        )}
    
                       
                    </tr>
                )
            } else if(nascosti.entrate.includes(item.id) && elementiNascosti){
                return(
                    <tr>
                        <td className={idEntrate.includes(item.id) ? item.id === 4000 ? ('block first-column totali nascosto') : ('block first-column last nascosto') : ('block first-column nascosto')}>
                        <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                            <span>{item.categoria}</span>
                            {!idEntrate.includes(item.id) && (
                                <span className='eye-firstColumn' style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                                <EyeIcon onClick={() => handleNascondi(item.id, 'rivela', 'entrate')} />
                                </span>
                            )}
                            </div>
                        </div>
                        </td>


                        
                        {showMore && (<td className={idEntrate.includes(item.id) ? item.id === 4000 ? (' totali corto') : (' last corto') : (' corto') }></td>)}
                        {showMore ? (
                            <td className={idEntrate.includes(item.id) ? item.id === 4000 ? ('column totali') : ('column last') : ('column ') }></td>
                        ) : (<td className={idEntrate.includes(item.id) ? item.id === 4000 ? ('corto totali') : ('corto last') : ('corto ') }></td>)}
    
                        {mesi.map((mese, index) => (
                            <>
                                {showMore ? (
                                    <React.Fragment key={mese}>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mese) ? (
                                        <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                            <div className='fatture-cash'>
                                                {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mese, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                            {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <>  
                                        {index === indiceMese && (
                                            <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}></td>
                                        )}
                                        {index > indiceMese-1 && (
                                            <React.Fragment key={mese}>
                                            {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mese) ? (
                                            <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                <div className='fatture-cash'>
                                                    {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}<EyeIcon onClick={() => handleImportiPopup(mese, item.categoria)}/>
                                                </div>
                                            </td>
                                            ) : (
                                            <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                {Number(item[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}
                                            </td>
                                            )}
                                        </React.Fragment>
                                        )}
                                    </>
                                )}
                            </>
                        ))}

                        {indiceCorrente >= 12 && (
                            <>
                                {mesi.map((mese, indiceMese) => (
                                    <React.Fragment key={indiceMese}>
                                        {(indiceMese <= indiceCorrente-12) && (
                                            <>
                                                {dati.successivi.entrate.map((itemInner, index) => (
                                                    <>  
                                                        {dati.successivi.macro.some(importo => importo.categoria === itemInner.categoria && importo.mese === mese) ? (
                                                            <>
                                                                {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                    <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                                        <div className='fatture-cash'>
                                                                            {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                            style: 'currency',
                                                                            currency: 'EUR'
                                                                            })}<EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')}/>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                    <td className={idEntrate.includes(item.id) ? (item.id === 4000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                                        {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                        style: 'currency',
                                                                        currency: 'EUR'
                                                                        })}
                                                                    </td>
                                                                )}
                                                            </>
                                                        )}
                                                        
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </React.Fragment>
                                    
                                ))}
                            </>
                        )}
    
                       
                    </tr>
                )
            }

        } else{
            return(
                <React.Fragment>
                    {idRidotti.includes(item.id) && (
                        <tr>
                            <td className='block first-column'>{item.categoria}</td>
                            {showMore && (<td className=' corto'></td>)}
                            {showMore ? (
                                <td className='column '></td>
                            ) : (<td className='corto '></td>)}

                            {mesi.map((mesi, index) => (
                                <>
                                    {showMore ? (
                                        <React.Fragment>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                        <td className='column'>
                                            <div className='fatture-cash'>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className='column'>
                                            {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                    ) : (
                                        <>
                                            {index === indiceMese && (
                                                <td className='column '></td>
                                            )}
                                            {index > indiceMese-1 && (
                                                <React.Fragment>
                                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                                <td className='column'>
                                                    <div className='fatture-cash'>
                                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                        })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                                    </div>
                                                </td>
                                                ) : (
                                                <td className='column'>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}
                                                </td>
                                                )}
                                            </React.Fragment>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}

                            {indiceCorrente >= 12 && (
                                <>
                                    {mesi.map((mese, indiceMese) => (
                                        <React.Fragment key={indiceMese}>
                                            {indiceMese <= indiceCorrente-12 && (
                                                <>
                                                    {dati.successivi.entrate.map((itemInner, index) => (
                                                        <>  
                                                            {dati.successivi.macro.some(importo => importo.categoria === itemInner.categoria && importo.mese === mese) ? (
                                                                <>
                                                                    {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                        <td className='column'>
                                                                            <div className='fatture-cash'>
                                                                                {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                                style: 'currency',
                                                                                currency: 'EUR'
                                                                                })}<EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')}/>
                                                                            </div>
                                                                        </td>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                        <td className='column'>
                                                                            {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                            style: 'currency',
                                                                            currency: 'EUR'
                                                                            })}
                                                                        </td>
                                                                    )}
                                                                </>
                                                            )}
                                                            
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </React.Fragment>
                                    
                                    ))}
                                </>
                            )}

                            
                        </tr>
                    )}
                </React.Fragment>
            )
        }
    }

    function displayUscite(item, indice){
        const idRidottiUscite = [0,1000,2000,3000,4000,5000];
        if(completo === 1){
            
            if(!nascosti.uscite.includes(item.id)){
                return(

                    <>
                        {item.id === 3000 && (
                        
                            <tr className='column'>
                                <td className='block first-column last'>
                                    Previsione IVA
                                </td>
                                {showMore && (<td className=' last corto'></td>)}
                                {showMore ? (
                                    <td className='column last'></td>
                                ) : (<td className='corto last'></td>)}
                                {dati.iva.map((item, index) => (
                                    <>  
                                        {showMore ? (
                                            <>
                                                {(index < indiceCorrente+1) && (
                                                    <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {index === indiceMese && (
                                                    <td className='column last'></td>
                                                )}
                                                {index > indiceMese-1 && (
                                                    <>
                                                        {(index < indiceCorrente+1) && (
                                                            <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                                                style: 'currency',
                                                                currency: 'EUR'
                                                            })}</td>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}

                            
                                
                            </tr>
                            
                        )}
                        <tr>
                            
                            <td className={idUscite.includes(item.id) ? item.id === 5000 ? ('block first-column totali') : ('block first-column last') : ('block first-column') }>
                            <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                <span>{item.categoria}</span>
                                {!idUscite.includes(item.id) && (
                                    <span className='eye-firstColumn' style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                                    <EyeIcon onClick={() => handleNascondi(item.id, 'nascondi', 'uscite')} />
                                    </span>
                                )}
                                </div>
                            </div>
                            </td>

                            {showMore && (<td className={idUscite.includes(item.id) ? item.id === 5000 ? (' totali corto') : (' last corto') : (' corto') }></td>)}
                            {showMore ? (<td className={idUscite.includes(item.id) ? item.id === 5000 ? ('column totali') : ('column last') : ('column') }></td>) : (<td className={idUscite.includes(item.id) ? item.id === 5000 ? ('corto totali') : ('corto last') : ('corto') }></td>)}

                            {mesi.map((mesi, index) => (
                                <>
                                    {showMore ? (
                                        <React.Fragment key={mesi}>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                        <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali' : 'column last') : 'column'}>
                                            <div className='fatture-cash'>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali' : 'column last') : 'column'}>
                                            {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                    ) : (
                                        <>
                                            {index === indiceMese && (
                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali' : 'column last') : 'column'}></td>
                                            )}
                                            {index > indiceMese-1 && (
                                                <React.Fragment key={mesi}>
                                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali' : 'column last') : 'column'}>
                                                    <div className='fatture-cash'>
                                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                        })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                                    </div>
                                                </td>
                                                ) : (
                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali' : 'column last') : 'column'}>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}
                                                </td>
                                                )}
                                            </React.Fragment>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                            
                            {indiceCorrente >= 12 && (
                                <>
                                    {mesi.map((mese, indiceMese) => (
                                        <React.Fragment key={indiceMese}>
                                            {indiceMese <= indiceCorrente-12 && (
                                                <>
                                                    {dati.successivi.uscite.map((itemInner, index) => (
                                                        <>  
                                                            {dati.successivi.macro.some(importo => importo.categoria === itemInner.categoria && importo.mese === mese) ? (
                                                                <>
                                                                    {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                        <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali' : 'column last') : 'column'}>
                                                                            <div className='fatture-cash'>
                                                                                {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                                style: 'currency',
                                                                                currency: 'EUR'
                                                                                })}<EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')}/>
                                                                            </div>
                                                                        </td>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                        <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali' : 'column last') : 'column'}>
                                                                            {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                            style: 'currency',
                                                                            currency: 'EUR'
                                                                            })}
                                                                        </td>
                                                                    )}
                                                                </>
                                                            )}
                                                            
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </React.Fragment>
                                    
                                    ))}
                                </>
                            )}
                            
                        </tr>
                    </>
                )
            } else if(nascosti.uscite.includes(item.id) && elementiNascosti){
                return(

                    <>
                        {item.id === 3000 && (
                        
                            <tr className='column'>
                                <td className='block first-column last'>
                                    Previsione IVA
                                </td>
                                {showMore && (<td className=' last corto'></td>)}
                                {showMore ? (<td className='column last'></td>) : (<td className='corto last'></td>)}
                                {dati.iva.map((item, index) => (
                                    <>
                                        {showMore ? (
                                            <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>
                                        ) : (
                                            <>
                                                {index === indiceMese && (
                                                    <td className='column last'></td>
                                                )}
                                                {index > indiceMese-1 && (
                                                    <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}
                                
                            </tr>
                            
                        )}
                        <tr>
                        <td className={idUscite.includes(item.id) ? item.id === 5000 ? ('block first-column totali nascosto') : ('block first-column last nascosto') : ('block first-column nascosto') }>
                            <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                <span>{item.categoria}</span>
                                {!idUscite.includes(item.id) && (
                                    <span className='eye-firstColumn' style={{ marginLeft: '8px', verticalAlign: 'middle' }}>
                                    <EyeIcon onClick={() => handleNascondi(item.id, 'rivela', 'uscite')} />
                                    </span>
                                )}
                                </div>
                            </div>
                            </td>


                            {showMore && (<td className={idUscite.includes(item.id) ? item.id === 5000 ? (' totali nascosto corto') : (' last nascosto corto') : (' nascosto corto') }></td>)}
                            
                            {showMore ? (<td className={idUscite.includes(item.id) ? item.id === 5000 ? ('column totali nascosto') : ('column last nascosto') : ('column nascosto') }></td>) : (<td className={idUscite.includes(item.id) ? item.id === 5000 ? ('corto totali nascosto') : ('corto last nascosto') : ('corto nascosto') }></td>)}

                            {mesi.map((mesi, index) => (
                                <>
                                    {showMore ? (
                                        <React.Fragment key={mesi}>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                        <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                            <div className='fatture-cash'>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                            {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                    ) : (
                                        <>
                                            {index === indiceMese && (
                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}></td>
                                            )}
                                            {index > indiceMese-1 && (
                                                <React.Fragment key={mesi}>
                                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                    <div className='fatture-cash'>
                                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                        })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                                    </div>
                                                </td>
                                                ) : (
                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                    })}
                                                </td>
                                                )}
                                            </React.Fragment>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                            {indiceCorrente >= 12 && (
                                <>
                                {mesi.map((mese, indiceMese) => (
                                <React.Fragment key={indiceMese}>
                                    {indiceMese <= indiceCorrente-12 && (
                                        <>
                                            {dati.successivi.uscite.map((itemInner, index) => (
                                                <>  
                                                    {dati.successivi.macro.some(importo => importo.categoria === itemInner.categoria && importo.mese === mese) ? (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                                    <div className='fatture-cash'>
                                                                        {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                        style: 'currency',
                                                                        currency: 'EUR'
                                                                        })}<EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')}/>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className={idUscite.includes(item.id) ? (item.id === 5000 ? 'column totali nascosto' : 'column last nascosto') : 'column nascosto'}>
                                                                    {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR'
                                                                    })}
                                                                </td>
                                                            )}
                                                        </>
                                                    )}
                                                    
                                                </>
                                            ))}
                                        </>
                                    )}
                                </React.Fragment>
                            
                            ))}
                                </>
                            )}
                        </tr>
                    </>
                )
            }

        }  else{
            return(
                <React.Fragment>
                    {idRidottiUscite.includes(item.id) && (
                        <>
                            {item.id === 3000 && (
                    
                                <tr className='column'>
                                    <td className='block first-column '>
                                        Previsione IVA
                                    </td>
                                    {showMore && (<td className=' corto'></td>)}
                                    {showMore ? (<td className='column '></td>) : (<td className='corto '></td>)}
                                    {dati.iva.map((item, index) => (
                                        <>
                                            {showMore ? (
                                                <td className='column '>{Number(item).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                            ) : (
                                                <>
                                                    {index === indiceMese && (
                                                        <td className='column'></td>
                                                    )}
                                                    {index > indiceMese-1 && (
                                                        <td className='column '>{Number(item).toLocaleString('it-IT', {
                                                            style: 'currency',
                                                            currency: 'EUR'
                                                        })}</td>
                                                    )}
                                                </>

                                            )}
                                        </>
                                    ))}
                                    
                                </tr>
                                
                            )}
                <tr>
                    <td className='block first-column'>{item.categoria}</td>
                    {showMore && (<td className=' corto'></td>)}
                    {showMore ? (<td className='column'></td>) : (<td className='corto'></td>)}
                    {mesi.map((mesi, index) => (
                        <>
                            {showMore ? (
                                <React.Fragment key={mesi}>
                                {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                <td className='column'>
                                    <div className='fatture-cash'>
                                        {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                        style: 'currency',
                                        currency: 'EUR'
                                        })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                    </div>
                                </td>
                                ) : (
                                <td className='column'>
                                    {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                    style: 'currency',
                                    currency: 'EUR'
                                    })}
                                </td>
                                )}
                            </React.Fragment>
                            ) : (
                                <>
                                    {index === indiceMese && (
                                        <td className='column'></td>
                                    )}
                                    {index > indiceMese-1 && (
                                        <React.Fragment key={mesi}>
                                        {importi.some(importo => importo.macrocategoria === item.categoria && importo.mese === mesi) ? (
                                        <td className='column'>
                                            <div className='fatture-cash'>
                                                {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                                })}<EyeIcon onClick={() => handleImportiPopup(mesi, item.categoria)}/>
                                            </div>
                                        </td>
                                        ) : (
                                        <td className='column'>
                                            {Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                            })}
                                        </td>
                                        )}
                                    </React.Fragment>
                                    )}
                                </>
                            )}

                        </>
                    ))}
                        {indiceCorrente >= 12 && (
                            <>
                                {mesi.map((mese, indiceMese) => (
                                <React.Fragment key={indiceMese}>
                                    {indiceMese <= indiceCorrente-12 && (
                                        <>
                                            {dati.successivi.uscite.map((itemInner, index) => (
                                                <>  
                                                    {dati.successivi.macro.some(importo => importo.categoria === itemInner.categoria && importo.mese === mese) ? (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className='column'>
                                                                    <div className='fatture-cash'>
                                                                        {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                        style: 'currency',
                                                                        currency: 'EUR'
                                                                        })}<EyeIcon onClick={() => handleImportiPopup(mese, itemInner.categoria, 'succ')}/>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                <td className='column'>
                                                                    {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                    style: 'currency',
                                                                    currency: 'EUR'
                                                                    })}
                                                                </td>
                                                            )}
                                                        </>
                                                    )}
                                                    
                                                </>
                                            ))}
                                        </>
                                    )}
                                </React.Fragment>
                            
                        ))}
                            </>
                        )}
                        </tr>
                        </>
                    )}
                </React.Fragment>
            )
        }
    }

  //displayFattureEntrate
  const [currentFattura, setCurrentFattura] = useState(null);
    
  function displayFattureEntrate(index, fattureRiba, innerType) {
    const meseCorrente = mesiNuovi[index].mese;
    const annoCorrente = mesiNuovi[index].anno;

    // Se il mese corrente è "Mese non valido", mostra una cella vuota
    if (meseCorrente === "Mese non valido") {
        const fatturaPresente = fattureRiba.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente);
        if (fatturaPresente) {
            // Se c'è un elemento, mostra i dati della fattura
            return (
                <td key={index} className='column'>
                    <div className='fatture-cash'>
                        {Number(fatturaPresente.importo).toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'EUR'
                        })}
                        <Eye onClick={() => handleSeePopup(fatturaPresente, 'entrate', index, innerType)} />
                    </div>
                </td>
            );
        } else {
            // Se non c'è un elemento, mostra una cella vuota
            return <td key={index} className='column'>/</td>;
        }
    }

    // Verifica se c'è un elemento per il mese corrente e l'anno corrente
    
    const fatturaPresente = fattureRiba.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente);   

    if (fatturaPresente) {
        // Se c'è un elemento, mostra i dati della fattura
        return (
            <td key={index} className='column'>
                <div className='fatture-cash'>
                    {Number(fatturaPresente.importo).toLocaleString('it-IT', {
                        style: 'currency',
                        currency: 'EUR'
                    })}
                    <Eye onClick={() => handleSeePopup(fatturaPresente, 'entrate', index, innerType)} />
                </div>
            </td>
        );
    } else {
        // Se non c'è un elemento, mostra una cella vuota
        return <td key={index} className='column'>/</td>;
    }
}




  function displayFattureUscite(index, fatture, innerType) {
    const meseCorrente = mesiNuovi[index].mese;
    const annoCorrente = mesiNuovi[index].anno;

    // Se il mese corrente è "Mese non valido", mostra una cella vuota
    if (meseCorrente === "Mese non valido") {
        const fatturaPresente = fatture.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente);
        if (fatturaPresente) {
            // Se c'è un elemento, mostra i dati della fattura
            return (
                <td key={index} className='column'>
                    <div className='fatture-cash'>
                        {Number(fatturaPresente.importo).toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'EUR'
                        })}
                        <Eye onClick={() => handleSeePopup(fatturaPresente, 'uscite', index, innerType)} />
                    </div>
                </td>
            );
        } else {
            // Se non c'è un elemento, mostra una cella vuota
            return <td key={index} className='column'>/</td>;
        }

    }

    // Verifica se c'è un elemento per il mese corrente
    const fatturaPresente = fatture.find(fattura => fattura.mese === meseCorrente && fattura.anno === annoCorrente);

    if (fatturaPresente) {
        // Se c'è un elemento, mostra i dati della fattura
        return (
            <td key={index} className='column'>
                <div className='fatture-cash'>
                    {Number(fatturaPresente.importo).toLocaleString('it-IT', {
                        style: 'currency',
                        currency: 'EUR'
                    })}
                    <Eye onClick={() => handleSeePopup(fatturaPresente, 'uscite', index, innerType)} />
                </div>
            </td>
        );
    } else {
        // Se non c'è un elemento, mostra una cella vuota
        return <td key={index} className='column'>/</td>;
    }
  }

  const [seePopup, setSeePopup] = useState(false);
  const [tipoFattura, setTipoFattura] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [tipoInterno, setTipoInterno] = useState(null);

  function handleSeePopup(item, tipo, index, interno){
    
    console.log("ITEM:")
    console.log(item);

    setCurrentIndex(mesiNuovi[index]);
    setTipoFattura(tipo);
    setTipoRicarica(tipo);
    setCurrentFattura(item);
    setTipoInterno(interno);

    setSeePopup(!seePopup);
  }

  useEffect(() => {
    
    console.log("INDEX");
    console.log(currentIndex);
    if(currentIndex !== null){
        if(tipoFattura === 'entrate'){
            if(tipoInterno === 'riba'){
                const oggettoTrovato = fattureRiba.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
                console.log(oggettoTrovato);
            }else{
                const oggettoTrovato = fatture.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
                console.log(oggettoTrovato);
            }
        } else{
            if(tipoInterno === 'riba'){
                const oggettoTrovato = fattureAcquistoRiba.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
            }else{
                
                
                const oggettoTrovato = fattureAcquisto.find(oggetto => oggetto.mese === currentIndex.mese);
                setCurrentFattura(oggettoTrovato);
            }
        }
    }

  }, [fatture, fattureAcquisto]);



  
  function handlePrevisione(e){
    const {value} = e.target;
    setPrevisione(parseInt(value));
  }

useEffect(() => {
    
    setMesi(mesiPass);
    setIndiceCorrente(() => {
        return mesiPass.indexOf(meseCorrente) + previsione
    });
    

}, [mesiPass, previsione]);






useEffect(() => {
    
    if(mesi !== null && mesiNuovi !== null){
        setMesi(mesi.slice(0, indiceCorrente + 1));
                
    }

    
    
}, [indiceCorrente])



    //visualizzazione iva
    async function changeVisualizzazione(){

        const passData = {
            tipo: visualizzazione
        }

        const response = await axios.post('https://www.app.nauticsm.it/cambia-visualizzazione', passData);
      
        ricaricaLeggera();


    }

    function handleChangeView(){
    
        if(visualizzazione === 'mensile'){
        setVisualizzazione('trimestrale')
        }else {
        setVisualizzazione('mensile');
        }
        
    }

    useEffect(() => {
        changeVisualizzazione()
    }, [visualizzazione]);


    const [showMore, setShowMore] = useState(false);
    function showAll(){
        setShowMore(!showMore);
    }


    useEffect(() => {
        console.log("*--------------------- DATI BANCA ---------------------*");
        console.log(dati.datiBanca);
        console.log("*--------------------- DATI SUCCESSIVI BANCA ---------------------*")
        console.log(dati.successivi.banca);
    }, [])



  return (
    <div>
        <IconMenu>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='icon-container tooltip' onClick={radioSelection}>
                    <span class="tooltiptext">Visualizzazione</span>
                    {completo === 2 ? (
                        <ExpandIcon onClick={radioSelection}/>
                    ) : (
                        <CompressIcon onClick={radioSelection}/>
                    )}
                </div>
                <div className='icon-container tooltip' style={{margin: '0px 10px'}} onClick={handleElementiNascosti}>
                    <span class="tooltiptext">Elementi Nascosti</span>
                    {!elementiNascosti ? (
                        <EyeIcon2 onClick={handleElementiNascosti} />
                    ) : (
                        <EyeIconClose2 onClick={handleElementiNascosti}/>
                    )}
                </div>
                <div className='icon-container tooltip' onClick={handleChangeView}>
                    <span class="tooltiptext">Visualizzazione IVA</span>
                    {visualizzazione === 'mensile' ? (
                        <OneIcon onClick={handleChangeView}/>
                    ) : (
                        <ThreeIcon onClick={handleChangeView}/>
                    )}
                </div>
            </div>        

        </IconMenu>
        <UnderMenu>
            <div style={{display: 'flex', alignItems: 'center', fontSize: '18px'}}>
                <p>Previsione: </p>
                <select style={{cursor: 'pointer', marginLeft: '10px'}} onChange={handlePrevisione}>
                    <option value={3}>3 mesi</option>
                    <option value={6}>6 mesi</option>
                    <option selected value={12}>Completo</option>
                </select>
            </div>
        </UnderMenu>
        <TableContainer style={{display: 'flex', justifyContent: 'center'}}>
        
        {(dati && fatture && mesi) ? (
            
            <div className="container-all" >
                <div className='scroll1' >
                    <table className='scroll-table'>
                        <thead >
                            <tr className='intestazione'>
                                <th className='block first-column'>Categorie</th>
                                <th className='columnNew corto'>{!showMore ? (
                                    <PlusIcon onClick={showAll} />
                                    ) : (
                                    <MinusIcon onClick={showAll} />
                                    )}
                                </th>

                                <th className='columnNew'>Precedenti</th>
                                {mesiNuovi.map((month, index) => (
                                <React.Fragment key={index}>
                                {showMore ? (
                                    <>
                                        {month.mese !== 'Mese non valido' && (
                                            <>
                                                {index <= previsione + indiceMese +1 && (
                                                    <th key={index} className="columnNew">
                                                        {month.mese}<span style={{fontSize: '12px'}}>({month.anno})</span>
                                                    </th>
                                                )}
                                            </>
                                        )}
                                    </>
                                ): (
                                    <>
                                        {(index >= indiceMese+1 && index <= previsione + indiceMese+1 ) && (
                                            <th key={index} className="columnNew">
                                                {month.mese}<span style={{fontSize: '12px'}}>({month.anno})</span>
                                            </th>
                                        )}
                                    </>
                                )}
                                
                                </React.Fragment>
                                ))}

                                
                            </tr>
                        </thead>
                        <tbody>
                            
                        <tr>
                            <td className='spazio-ridotto'></td>
                        </tr>
                        
                        <tr>
                            <td className='block first-column totali'></td>
                            <td className='interlinea important' colSpan="30">DEPOSITI BANCARI</td>
                        </tr>
                        {dati.datiBanca.map((item, index) => (
                            displayBanca(item, index)
                        ))}
                        

                        <tr>
                        <td className='spazio-ridotto'></td>
                        </tr>
                        <tr>
                            <td className='block first-column totali'></td>
                            <td className='interlinea important' colSpan="30">entrate di gestione</td>
                        </tr>
                            
                        
                        <tr className='column'>
                            <td className='block first-column'>Fatture Entrate Riba</td>
                            <td className='corto'></td>
                        
                            {mesiNuovi.map((mese, index) => (
                                <>
                                    {index <= indiceCorrente+1 && (
                                        <>
                                            {showMore ? (
                                                <React.Fragment key={index}>
                                                    {displayFattureEntrate(index, fattureRiba, 'riba')}
                                                </React.Fragment>
                                            ) : (
                                                <>
                                                    {index === indiceMese && (
                                                        <>{displayFattureEntrate(0, fattureRiba, 'riba')}</>
                                                    )}
                                                    {index > indiceMese && (
                                                        <React.Fragment key={index}>
                                                            {displayFattureEntrate(index, fattureRiba, 'riba')}
                                                        </React.Fragment>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                            
                        </tr>
                        <tr className='column'>
                            <td className='block first-column'>Altre Fatture Entrate</td>
                            <td className=' corto'></td>
                            
                            {mesiNuovi.map((mese, index) => (
                                <>
                                    {index <= indiceCorrente+1 && (
                                        <>
                                            {showMore ? (
                                                <React.Fragment key={index}>
                                                    {displayFattureEntrate(index, fatture, 'normali')}
                                                </React.Fragment>
                                            ): (
                                                <>
                                                    {index === indiceMese && (
                                                        <>{displayFattureEntrate(0, fatture, 'normali')}</>
                                                    )}
                                                    {index > indiceMese && (
                                                        <React.Fragment key={index}>
                                                        {displayFattureEntrate(index, fatture, 'normali')}
                                                    </React.Fragment>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                        </tr>
                                            
                        {nascosti && (
                            <>
                                {dati.datiEntrate.map((item, index) => (
                                    displayEntrate(item, index)
                                ))}
                            </>
                        )}
                        
                        
                        
                    
                        

                        <tr>
                        <td className='spazio-ridotto'></td>
                        </tr>
                        <tr>
                        <td className='block first-column totali'></td>
                            <td className='interlinea important' colSpan="30">uscite di gestione</td>
                        </tr>
                        
                        <tr className='column'>
                            <td className='block first-column'>Fatture Uscite Riba</td>
                            <td className=' corto'></td>
                            
                            {mesiNuovi.map((mese, index) => (
                                <>
                                    {index <= indiceCorrente+1 && (
                                        <>
                                            {showMore ? (
                                                <React.Fragment key={index}>
                                                    {displayFattureUscite(index, fattureAcquistoRiba, 'riba')}
                                                </React.Fragment>
                                            ): (
                                                <>
                                                    {index === indiceMese && (
                                                        <>{displayFattureUscite(0, fattureAcquistoRiba, 'riba')}</>
                                                    )}
                                                    {index > indiceMese && (
                                                        <React.Fragment key={index}>
                                                        {displayFattureUscite(index, fattureAcquistoRiba, 'riba')}
                                                    </React.Fragment>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                        </tr>
                        <tr className='column'>
                            <td className='block first-column'>Altre Fatture Uscite</td>
                            <td className=' corto'></td>
                            
                            {mesiNuovi.map((mese, index) => (
                                <>
                                    {index <= indiceCorrente+1 && (
                                        <>
                                            {showMore ? (
                                                <React.Fragment key={index}>
                                                {displayFattureUscite(index, fattureAcquisto, 'normali')}
                                            </React.Fragment>
                                            ) : (
                                                <>
                                                    {index === indiceMese && (
                                                        <>{displayFattureUscite(0, fattureAcquisto, 'normali')}</>
                                                    )}
                                                    {index > indiceMese && ( 
                                                        <React.Fragment key={index}>
                                                        {displayFattureUscite(index, fattureAcquisto, 'normali')}
                                                    </React.Fragment>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                        </tr>
                        

                        {nascosti && (
                            <>
                                {dati.datiUscite.map((item, index) => (
                                    displayUscite(item, index)
                                ))}
                            </>
                        )}
                        
                        <tr>
                        <td className='spazio-ridotto'></td>
                        </tr>

                        {dati.datiBanca.map((item, indice) => (
                            <React.Fragment>
                                {item.id === 2000 && (completo === 1) && (
                                    <tr>
                                        <td className='block first-column totali'>{item.categoria}</td>
                                        {showMore && (<td className=' totali corto'></td>)}
                                        {showMore ? (<td className='column totali'></td>) : (<td className='corto totali'></td>)}
            
                                        {mesi.map((mesi, index) => (
                                           <>
                                            {showMore ? (
                                                 <td className='column totali'>{Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                            ) : (
                                                <>
                                                    {index === indiceMese && (
                                                    <td className= 'column totali'></td>
                                                )}
                                                    {index > indiceMese-1 && (
                                                    <td className='column totali'>{Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>
                                                )}
                                                </>
                                            )}
                                           </>
                                        ))}
                                        {indiceCorrente >= 12 && (
                                            <>
                                                {mesi.map((mese, indiceMese) => (
                                                    <React.Fragment key={indiceMese}>
                                                        {indiceMese <= indiceCorrente-12 && (
                                                            <>
                                                                {dati.successivi.banca.map((itemInner, index) => (
                                                                    <>
                                                                        {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                            <td className='column totali'>
                                                                                {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                                style: 'currency',
                                                                                currency: 'EUR'
                                                                                })}
                                                                            </td>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                    
                                                ))}
                                            </>
                                        )}
                                    </tr>
                                )}
                                {item.id === 2000 && (completo === 2) && (
                                    <tr>
                                        <td className='block first-column'>{item.categoria}</td>
                                        {showMore && (<td className=' corto'></td>)}
                                        {showMore ? (<td className='column '></td>) : (<td className='corto '></td>)}
            
                                        {mesi.map((mesi, index) => (
                                            <>
                                                {showMore ? (
                                                    <td className='column'>{Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>
                                                ) : (
                                                    <>
                                                        {index === indiceMese && (
                                                            <td className= 'column'></td>
                                                        )}
                                                        {index > indiceMese-1 && (
                                                            <td className='column'>{Number(item[mesi.toLowerCase()]).toLocaleString('it-IT', {
                                                                style: 'currency',
                                                                currency: 'EUR'
                                                            })}</td>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                        {indiceCorrente >= 12 && (
                                            <>
                                                {mesi.map((mese, indiceMese) => (
                                                    <React.Fragment key={indiceMese}>
                                                        {indiceMese <= indiceCorrente-12 && (
                                                            <>
                                                                {dati.successivi.banca.map((itemInner, index) => (
                                                                    <>
                                                                        {itemInner[mese.toLowerCase()] !== undefined && index === indice && (
                                                                            <td className='column'>
                                                                                {Number(itemInner[mese.toLowerCase()]).toLocaleString('it-IT', {
                                                                                style: 'currency',
                                                                                currency: 'EUR'
                                                                                })}
                                                                            </td>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                    
                                                ))}
                                            </>
                                        )}
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}


                        </tbody>

                    </table>

                </div>                
                
            </div>
    
        ) : (
            <p>Caricamento...</p>
        )}
        
        </TableContainer>
        {seePopup && (<CashFlowPopup close={handleSeePopup} fattura={currentFattura} tipo={tipoFattura} ricarica={handleRicarica} aggiornamento={aggiornamento}/>)}
        {displayImporti && (<ImportiPopup mese={importiMese} categoria={importiCategoria} close={handleImportiPopup} tipo={tipoImporti}/>)}
    </div>

    
  )
}

export default CashFlowTable;

const IconMenu = styled.div`
    width: 100%;
    margin-top: 30px;
    padding-left: 2%;
    padding-right: 2%;

    .icon-container{
        border: 1px solid rgba(0,0,0,.5);
        border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
        width: fit-content;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
        cursor: pointer;
        color: rgba(0,0,0,.5);
        transition: all .3s linear;
    }

    .icon-container:hover{
        border: 1px solid black;
        color: black;
    }
`
const CompressIcon = styled(FaCompressAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const ExpandIcon = styled(FaExpandAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const UnderMenu = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-left: 2%;
    padding-right: 2%;
`

const UpperMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .radio{
    
    margin-top: 20px;
    display: flex;
    align-items: center;
  }

  .radio input, .radio label{
    cursor: pointer;
    font-size: 20px;
  }

  .radio label{
    margin-right: 15px;
  }

  .visualizzazione-iva{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .visualizzazione-switch{
    width: 80px;
    background-color: rgba(0,0,0,.2);
    border-radius: 20px;
    height: 25px;
    margin: 0px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    ${({ visualizzazione }) => (visualizzazione === 'mensile' ? 'justify-content: flex-start;' : 'justify-content: flex-end;')};
  }

  .inner-switch{
     width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #213940;
        cursor: pointer;
        margin: 0px 3px;
  }


`

const TableContainer = styled.div`
    
    .eye-firstColumn{
        color: rgba(0,0,0,.3);
        transition: all .3s linear;
    }
    .first-column:hover{
        .eye-firstColumn{
            color: rgba(0,0,0,1);
            cursor: pointer;
        }    
    }

    .nascosto{
        color: rgba(0,0,0,.3);
    }

    .container-all{
        display: flex;
        margin-top: 30px;        
        overflow-x: auto;
        width: 96%;
        
    }

    .scroll1{
        overflow-x: auto;
        white-space: nowrap;
        border-radius: 10px;
        width: 100%;
        
    }

    table{
        width: 100%;
    }

    .scroll1 td.first-column {
    position: sticky;
    left: 0;
    z-index: 3;
}

.scroll1 td.first-column::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0; /* Estendi il bordo per tutta l'altezza della cella */
    left: -1px; /* Posiziona il bordo sinistro */
    width: 2px; /* Larghezza del bordo sinistro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}

.scroll1 td.first-column::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px; 
    height: 100%;
    width: 2px; 
    background-color: var(--blue-navy);
    z-index: 2;
}

.scroll1 td.spazio-ridotto {
    position: sticky;
    left: 0;
    z-index: 2;
}

.scroll1 td.spazio-ridotto::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px; /* Posiziona il bordo sinistro */
    height: 100%;
    width: 2px; /* Larghezza del bordo sinistro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}

.scroll1 td.spazio-ridotto::after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px; /* Posiziona il bordo destro */
    height: 100%;
    width: 2px; /* Larghezza del bordo destro */
    background-color: var(--blue-navy); /* Colore del bordo */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla cella */
}



    th.corto, td.corto{
        min-width: 22px;
        position: relative;
    }
`

const Choice = styled.div`
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;

    .switch-view{
        width: 80px;
        background-color: rgba(0,0,0,.2);
        border-radius: 20px;
        height: 25px;
        margin: 0px 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: ${({completo}) => ((completo === 1) ? 'flex-start' : 'flex-end')};
    }

    .switch-view-nasc{
        width: 80px;
        background-color: rgba(0,0,0,.2);
        border-radius: 20px;
        height: 25px;
        margin: 0px 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: ${({elementiNascosti}) => (elementiNascosti ? 'flex-start' : 'flex-end')};
    }

    .switch-btn{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #213940;
        cursor: pointer;
        margin: 0px 3px;
    }

    
`

const Eye = styled(LuFileSpreadsheet)`
    margin-left: 7px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 20px;
    &:hover{
        
        color: rgba(0,0,0,.7)
    }
`

const EyeIcon = styled(FaEye)`
    font-size: 20px;
    margin-left: 7px;
    cursor: pointer;
`


const EyeIconClose = styled(IoEyeOff)`
    font-size: 20px;
    margin-left: 7px;
    cursor: pointer;
`

const EyeIcon2 = styled(FaEye)`
    font-size: 20px;
    cursor: pointer;
`


const EyeIconClose2 = styled(IoEyeOff)`
    font-size: 20px;
    cursor: pointer;
`

const PlusIcon = styled(CiCirclePlus)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    
`

const MinusIcon = styled(CiCircleMinus)`
   position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    
`

const OneIcon = styled(PiNumberOneBold)`
    font-size: 20px;
    cursor: pointer;
`

const ThreeIcon = styled(PiNumberThreeBold)`
    font-size: 20px;
    cursor: pointer;
`